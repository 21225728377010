/* eslint-disable require-jsdoc */
import BaseApi from 'nast-api'
import BaseRequestBuilder from 'nast-api/RequestBuilder'

/**
 *
 */
export default class NastApi extends BaseApi {
  constructor(requestBuilder, config = {}, store, version = '1') {
    super(requestBuilder, config)
    this._store = store
    this.version = version
        
    this._isRefreshing = false;
    this._failedRequestsQueue = []

    this._instances['default'].interceptors.response.use((response) => {
      return response
    }, async (error) => {
      const originalRequest = error.config;
      if (error.response && error.response.status === 401 && !originalRequest._retry) {

        if (this._isRefreshing) {
          return new Promise((resolve, reject) => {
            this._failedRequestsQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              return this._instances['default'](originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }

        this._isRefreshing = true
        originalRequest._retry = true;

        return new Promise((resolve, reject) => {
          this.refreshAccessToken()
            .then(({ token }) => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
                        
              this._failedRequestsQueue.forEach((prom) => prom.resolve(token));
              this._failedRequestsQueue = [];
              
              resolve(this._instances['default'](originalRequest));
            })
            .catch((err) => {
              this._failedRequestsQueue.forEach((prom) => prom.reject(err));
              this._failedRequestsQueue = [];

              $app.auth.logout()
              reject(err);
            })
            .finally(() => {
              this._isRefreshing = false;
            });
        });

      }
      return Promise.reject(error);
    });

    this._instances['default'].interceptors.request.use((config) => {
      const token = this._getAccessToken();
      config.headers.Authorization = `Bearer ${token}`;
      (config.url.startsWith('/v1/v2/')) && (config.url = config.url.replace('/v1/v2/', '/v2/'))
      return config;
    }, (error) => {
      // Do something with request error
      return Promise.reject(error);
    });
  }

  async refreshAccessToken() {
    let refreshToken = await $app.auth.refreshToken()
    if (!refreshToken || refreshToken === 'undefined') {
      const lsAuth = JSON.parse(localStorage.getItem('auth'))
      const token = lsAuth?.token
      if (this.enforceTokenValidityPeriod(token)) {
        refreshToken = token
      } else return
    }
    const response = await this._instances['default'].post('/v1/auth/refresh-token', { refreshToken, })
    const content = response.data
    $app.auth.login(content.data.user, content.data.token, content.data.grants, content.data.refreshToken, false)
    return content.data.token
  }

  enforceTokenValidityPeriod(token) {
    if (token) {
      const decodedToken = atob(token.split('.')[1])
      const tokenData = JSON.parse(decodedToken)
      const creationTime = tokenData.iat
      const issuedAt = new Date(creationTime * 1000)

      const allowedAfter = new Date('2023-11-07T00:00:00.000Z')
      const allowedBefore = new Date('2024-01-10T00:00:00.000Z')
      return ((issuedAt > allowedAfter) && (issuedAt < allowedBefore))
    }
    return false
  }

  _getAccessToken() {
    return this._store.getters['auth/token']
  }
}

/**
 *
 */
export class RequestBuilder extends BaseRequestBuilder {

}

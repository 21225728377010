<template>
  <div class="c-new-application">
    <n-loader :loading="$var('load')"></n-loader>
    <div class="modal-body">
      <div class="close-button" @click="close">
        <img :src="imgClose" alt="">
      </div>
      <h2>Новая заявка</h2>
      <p>Опишите вашу проблему, и мы обязательно свяжемся в ближайшее время!</p>
      <n-input placeholder="Тема заявки" v-bind="$form.input('title')" />
      <n-input class="app-textarea" placeholder="Описание проблемы" v-bind="$form.input('content')"></n-input>
      <div class="button-place">
        <n-button class="user-default-button" @click="send">Отправить</n-button>
      </div>
    </div>
  </div>
</template>

<script>
import imgClose from 'assets/close-icon.svg'
export default {
  name: 'CModalContacts',
  data() {
    return {
      imgClose,
    }
  },
  created() {
    this.$form.init({
      title: '',
      content: '',
    })
    this.$form.rules({
      title: [ 'required', ],
      content: [ 'required', ],
    })
  },
  methods: {
    close() {
      this.$emit('update:show', false)
    },
    send() {
      if (this.$form.check()) {
        this.$var('load', true)
        $api.application.create(this.$form.get()).then(() => {}).finally(() => {
          this.$var('load', false)
          this.close()
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.c-new-application {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.41);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;

  .modal-body {
    background-color: #F9F9F9;
    border-radius: 12px;
    position: relative;
    padding: 40px 20px 20px 20px;
    .close-button {
      position: absolute;
      right: 20px;
      top: 20px;
    }
    h2 {
      margin: 0;
      font-weight: bold;
      font-size: 1.2em;
    }
    p {
      color: black;
      margin: 5px 0 15px 0;
      font-size: 0.9em;
    }

    .n-input {
      ::v-deep .n-content {
        border: none;
      }
    }
    textarea:focus {
      outline: none;
    }
    .app-textarea {
      margin-top: 10px;
      width: 100%;
      background-color: #FFFFFF;
      border: none;
      min-width: 250px;
      min-height: 130px;

    }

  }
  .button-place {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 30px;
    .user-default-button {
      width: 200px;
      height: 44px;
      background-color: var(--primary);
      border-radius: 8px;
      color: #ffffff;
      font-size: 1.1em;
      font-weight: bold;
    }
  }
}
</style>

<!-- eslint-disable max-len -->
<template>
  <div class="c-phone-verify">
    <c-go-back :backroute="backroute" @click="closeCode" />
    <div v-show="show" class="content">
      <n-loader :loading="$var('load')" />
      <div class="text">
        <h1><t name="1.4.1" /></h1>
        <p><t name="1.4.2" /></p>
      </div>
      <c-code-input :received="code" :code.sync="code" />
      <div class="lower-text">
        <p :class="{danger: !!dangerMessage}">{{ dangerMessage || $app.secure.clean($n.t('1.4.3')) }}</p>
        <p v-show="!timerEnded" ref="timerDisplay">
          <t name="1.4.7" />
          <span id="timer" class="timer">{{ timer }}</span>
        </p>
        <p v-show="timerEnded" qa-id="auth-page-verify-code-resend" @click="sendCode"><t name="1.4.4" /></p>
      </div>
      <div class="button-place">
        <n-button type="submit" class="user-default-button" qa-id="auth-page-verify-code-submit-button" :disabled="isDisabled" @click="close()"><t name="1.4.5" /></n-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CPhoneVerify',
  props: {
    backroute: {
      type: String,
      default: 'mobile.profile.security',
    },
    iin: {
      type: String,
      required: true,
    },
    phone: {
      type: String,
      required: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    documentId: {
      type: Number,
      required: false,
    },
    isPermanentOtp: {
      type: Boolean,
      default: false,
      required: false,
    }
  },
  data() {
    return {
      code: '',
      isDisabled: true,
      show: true,
      timer: 0,
      interval: null,
      timerEnded: false,
      dangerMessage: '',
      resendDelays: [ 30, 60, 120, 300, 1800 ],
      currentDelayIndex: 0,
    }
  },
  watch: {
    code() {
      if (this.code.length === 4) {
        this.isDisabled = false
      }
    },
  },
  beforeDestroy() {
    this.removeListener()
  },
  created() {
    if (this.phone === undefined) {
      this.$router.push({ name: 'login', })
    } else if (!this.isPermanentOtp) {
      this.sendCode()
    }
  },
  mounted() {
    this.startTimer()
  },
  methods: {
    formatTime(seconds) {
      const minutes = Math.floor(seconds / 60);
      const secs = seconds % 60;
      return `0${minutes}:${secs.toString().padStart(2, '0')}`;
    },
    setCode() {
      document.addEventListener('onSMSArrive', (args) => {
        this.code = args.message.substr(0, 4)
      })
    },
    removeListener() {
      document.removeEventListener('onSMSArrive', (args) => {})
    },
    listenSms() {
      this.setCode()
      cordova.plugins.SMSRetriever.startWatch((r) => {}, (e) => {})
    },
    closeCode() {
      this.$emit('update:show', false)
    },
    startTimer() {
      this.timerEnded = false;
      let timerValue = this.timer;
      const timerElement = this.$refs.timerDisplay.querySelector('#timer');

      this.interval = setInterval(() => {
        if (timerValue > 0) {
          timerValue--;
          timerElement.textContent = this.formatTime(timerValue);
        } else {
          clearInterval(this.interval);
          this.timerEnded = true;
        }
      }, 1000);
    },
    resetTimer() {
      clearInterval(this.interval);
      const nextDelay = this.resendDelays[Math.min(this.currentDelayIndex, this.resendDelays.length - 1)];
      this.timer = nextDelay;
      if (this.currentDelayIndex < this.resendDelays.length - 1) {
        this.currentDelayIndex++;
      }
      this.startTimer();
    },
    sendCode() {
      this.$var('load', true)
      const data = {
        iin: this.iin,
        phone: this.phone,
        documentId: this.documentId
      }
      
      if (this.isPermanentOtp) {
        $api.auth.sendPermanentCodeOtp(data).then((response) => {
          if (Capacitor.getPlatform() === 'android') {
            this.listenSms()
          }
          // this.startTimer()
          this.resetTimer();
        }).catch(() => {
          this.$emit('update:show', false)
        }).finally(() => {
          this.$var('load', false)
        })
      } else {
        $api.auth.sendCode(this.iin, this.phone).then((response) => {
          if (Capacitor.getPlatform() === 'android') {
            this.listenSms()
          }
          // this.startTimer()
          this.resetTimer();
        }).catch(() => {
          this.$emit('update:show', false)
        }).finally(() => {
          this.$var('load', false)
        })
      }

    },
    close() {
      if (this.code.length === 4) {
        this.$var('load', true)
        if (this.isPermanentOtp) {
          $api.auth.checkPermanentCodeOtp(this.documentId, { code: this.code }).then((response) => {
            if (response.data.content.isAvailable) {
              this.$emit('update:state', true)
              this.closeForm()
            } else {
              this.$emit('update:state', false)
              this.closeForm()
            }
          }).catch((response) => {
            this.dangerMessage = $app.secure.clean($n.t(response.response.data.message))
          }).finally(() => {
            this.$var('load', false)
          })
        } else {
          $api.auth.checkCode(this.iin, { code: this.code, }).then((response) => {
            if (response.data.content.isAvailable) {
              this.$emit('update:state', true)
              this.closeForm()
            } else {
              this.$emit('update:state', false)
              this.closeForm()
            }
          }).catch((response) => {
            this.dangerMessage = $app.secure.clean($n.t(response.response.data.message))
          }).finally(() => {
            this.$var('load', false)
          })
        }

      }
    },
    closeForm() {
      this.$emit('update:show', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.c-phone-verify {
  z-index: 11;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: #F9F9F9;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;

  ::v-deep{
    .c-go-back{
      position: absolute;
      top: calc(20px + env(safe-area-inset-top));
      left: 25px;
    }
  }

  .content {
    .text {
      h1 {
        font-size: 24px;
        color: #1c1c1c;
        font-weight: 500;
        text-align: center;
        margin: 0 0 5px 0;
      }
      p {
        margin: 0 auto 30px;
        text-align: center;
        width: 80%;
      }
    }
    .lower-text {
      p {
        margin: 0;
        font-size: 14px;
        text-align: center;
        color: #A91CD2;
        text-decoration: underline;
        padding: 10px 0;

        &:first-child {
          font-size: 12px;
          color: #9CA3AF;
          text-decoration: none;
          padding: 0;
          &.danger {
            color: red;
            font-size: 16px;
          }
        }
      }
    }
    .button-place {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
      .user-default-button {
        width: 90%;
        display: block;
        margin: 0 auto;
      }
    }
  }
}
</style>
